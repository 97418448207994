<template>
  <div class="invitation">

      <div class="invitation-bg">
            <div class="invitation-cover"></div>
           <van-image :src="require('../../assets/img/invitation-bg.png')"  :width="375/37.5+'rem'" :height="257/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
      </div>

      <div class="invitation-item">
          <h1>邀请好友<i></i></h1>
          <p>只需将您的专属邀请页（内附专属邀请码）分享至QQ群、微信群、朋友圈、微博、设计师网站等渠道，每邀请一个好友使用您的专属邀请码注册后<em>100天</em>内</p>

          <div class="img">
              <div class="invitation-cover"></div>
               <van-image :src="require('../../assets/img/invitation-coupon_1.png')"  :width="295/37.5+'rem'" :height="63/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
          </div>
           <div class="img">
              <div class="invitation-cover"></div>
               <van-image :src="require('../../assets/img/invitation-coupon_2.png')"  :width="295/37.5+'rem'" :height="63/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
          </div>

          <c-button bClass="btn" @btnClick="onPageToUrl">分享我的专属邀请页</c-button>
      </div>

      <div class="invitation-item">
          <h1>分享作品<i></i></h1>
          <p>用户登陆后所有作品里都会带有您的专属邀请码，只需将您和其他用户的作品分享至可跳转链接的第三方平台，每当有用户通过您分享的作品网址跳转至享设计注册后<em>100天</em>内</p>

          <div class="img">
              <div class="invitation-cover"></div>
               <van-image :src="require('../../assets/img/invitation-coupon_1.png')" lazy-load :width="295/37.5+'rem'" :height="63/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
          </div>
           <div class="img">
              <div class="invitation-cover"></div>
               <van-image :src="require('../../assets/img/invitation-coupon_2.png')" lazy-load :width="295/37.5+'rem'" :height="63/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
          </div>

          <div class="img_l">
              <div class="invitation-cover"></div>
              <van-image :src="require('../../assets/img/invitation-img_2.png')" lazy-load :width="295/37.5+'rem'" :height="242/37.5+'rem'" fit="contain" loading-icon="" error-icon=""></van-image>
          </div>
      </div>

      <div class="invitation-item">
          <h1>我的邀请<i></i></h1>
            <div class="invitation-data">
                <div class="item">
                    <h2>{{inviteData.reg_num}}<i>人</i></h2>
                    <p>已邀请的注册用户</p>
                </div>

                <div class="item">
                    <h2>{{inviteData.vip_recharge_num}}<i>人</i></h2>
                    <p>已邀请的VIP会员</p>
                </div>

                <div class="item">
                    <h2>{{inviteData.re_amount_num}}<i>人</i></h2>
                    <p>已邀请的充值用户</p>
                </div>

                <div class="item">
                    <h2>{{inviteData.amount_total}}<i>元</i></h2>
                    <p>已获得的邀请收益</p>
                </div>
            </div>

            <div class="cue">* 邀请收益请到PC官网提现</div>
      </div>
  </div>
</template>

<script>
import { Image } from "vant";
import CButton from "../../components/form/CButton";
import { mapActions, mapState } from "vuex";
import { toastLoading ,closeToast} from "../../lib/util";
export default {
    components: {
        [Image.name]: Image,
        [CButton.name]: CButton,
    },
    data(){
        return {
            time:null
        }
    },
    computed: {
        ...mapState({
            inviteData: (state) => state.me.inviteData,
        }),
    },
    methods: {
        ...mapActions({
            requestInvitation: "me/requestInvitation",
        }),
        onPageToUrl() {
            this.$router.push({
                path:"/share",
                query:{
                    code:this.inviteData.invite_code
                }
            })
        },
    },
    created() {
        this.requestInvitation();
    },
};
</script>

<style>
.invitation{ padding: 187px 20px 12px;}
.invitation-cover{width:100%; height: 100%; position: absolute; left: 0; top: 0; z-index: 100;}
.invitation-bg{position: absolute; left: 0; top: 47px; width: 100%; height: 257px; z-index: -1;}
.invitation-item{width:100%; border-radius: 4px; overflow: hidden; background: #fff; padding: 20px; margin-bottom: 20px;}
.invitation-item h1{text-align: center; font-size: 20px; color: #2E2E2E;  position: relative; margin-bottom: 28px; font-weight: normal;}
.invitation-item h1 i{display: block; width:30px; height: 2px; background: #F85659; position: absolute; left: 0; right: 0; bottom: -8px; margin: auto;}
.invitation-item p{ color: #B3B3B3; font-size: 14px; line-height: 22px; margin-bottom: 20px;}
.invitation-item p em{color:#2E2E30 ; font-style: normal; font-size: 14px; margin: 0 4px;}
.invitation-item p em.red{color:#F85659 ; text-decoration: underline;}
.invitation-item .img{margin-bottom: 12px;}
.invitation-item .img_l{margin-top: 32px;}
.invitation-item .btn{margin-top: 32px; margin-bottom: 12px;}
.invitation-data{display: flex; justify-content: space-between; align-items: flex-start; flex-wrap:wrap}
.invitation-data .item{width:143px; padding: 24px 0; border-radius: 4px; background:#F5F5F5 ; margin-bottom: 12px;}
.invitation-data .item h2{font-size: 36px; text-align: center;color: #F95659; font-weight: normal;}
.invitation-data .item h2 i{font-style: normal;font-size: 14px; }
.invitation-data .item p{text-align: center; font-size: 12px; color: #666666; margin: 0;}
.invitation-item .cue{text-align: center; font-size: 12px; color: #B3B3B3; margin-top: 8px;}
.invitation-item:last-child{margin-bottom: 0;}
</style>